import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

function Products() {
  return (
    <Layout>
      <SEO
        keywords={[`aluminum profile`, `cnc`, `automation`, `window`]}
        title="Products"
      />
      <section>
        <div className="pt-12 sm:pt-16 lg:pt-24">
          <div className="max-w-screen-lg px-4 mx-auto space-y-16 sm:px-6 lg:px-8">
            <div className="video">
              <h3 className="mb-3 text-xl">
                Uni_Link Automation Software: Productbuilder
              </h3>
              <div
                className="relative block w-full overflow-hidden"
                style={{ paddingTop: '56.25%' }}
              >
                <iframe
                  title="video1"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/zR9TEKu_muo?rel=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
              <p className="text-center">Subtitle</p>
            </div>
            <div className="video">
              <h3 className="mb-3 text-xl">
                Uni_Link Automation Software: Step File Converter
              </h3>
              <div
                className="relative block w-full overflow-hidden"
                style={{ paddingTop: '56.25%' }}
              >
                <iframe
                  title="video1"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/C5kMMGpqsc0?rel=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
              <p className="text-center">Subtitle</p>
            </div>
            {/* VIDEO */}
            <div className="video">
              <h3 className="mb-3 text-xl">
                Uni_Link Automation Software: MACRO
              </h3>
              <div
                className="relative block w-full overflow-hidden"
                style={{ paddingTop: '56.25%' }}
              >
                <iframe
                  title="video1"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/zfqR-aD13V8?rel=0"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
            </div>
            <div className="video">
              <h3 className="mb-3 text-xl">
                Uni_Link Automation Software: LIST
              </h3>
              <div
                className="relative block w-full overflow-hidden"
                style={{ paddingTop: '56.25%' }}
              >
                <iframe
                  title="video1"
                  width="560"
                  height="315"
                  src="https://www.youtube.com/embed/R03ys9YftX4?rel=-0?"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full"
                ></iframe>
              </div>
              <p className="text-center">Subtitle</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default Products
